<template>
  <div class="row row-equal">
    <div class="flex xl12 xs12">
      <div class="row">
        <div class="flex xs12 md10">
          <h1 style="font-size: 24px" class="mb-2">Uncategorized</h1>
          <span>This is description for uncategorized category</span>
        </div>
        <div class="flex xs12 md2 align-self-bottom">
          <va-input v-model="simple" placeholder="Text Input" />
        </div>
      </div>
      <div class="row mt-3">
        <div class="flex xs12 md4">
          <va-card>
            <va-card-title>
              <va-chart
                class="vertical-chart"
                :data="verticalBarChartData"
                type="vertical-bar"
              />
            </va-card-title>
            <va-card-content>
              <div>
                <div class="mb-2" style="color: #007CFF;">
                  Uncategorized
                </div>
                <div class="card-title">
                  Tablet Ownership di Indonesia
                </div>
                <div class="mt-4">
                  Approximatelly 54% percent of adults in Indonesia owned at
                  least one tablet, as of February 2021. The tablet ownership
                  rate in Indonesia has ...
                </div>
              </div>
            </va-card-content>
          </va-card>
        </div>
        <div class="flex xs12 md4">
          <va-card>
            <va-card-title>
              <va-chart
                class="vertical-chart"
                :data="verticalBarChartData"
                type="vertical-bar"
              />
            </va-card-title>
            <va-card-content>
              <div>
                <div class="mb-2" style="color: #007CFF;">
                  Uncategorized
                </div>
                <div class="card-title">
                  Tablet Ownership di Indonesia
                </div>
                <div class="mt-4">
                  Approximatelly 54% percent of adults in Indonesia owned at
                  least one tablet, as of February 2021. The tablet ownership
                  rate in Indonesia has ...
                </div>
              </div>
            </va-card-content>
          </va-card>
        </div>
        <div class="flex xs12 md4">
          <va-card>
            <va-card-title>
              <va-chart
                class="vertical-chart"
                :data="verticalBarChartData"
                type="vertical-bar"
              />
            </va-card-title>
            <va-card-content>
              <div>
                <div class="mb-2" style="color: #007CFF;">
                  Uncategorized
                </div>
                <div class="card-title">
                  Tablet Ownership di Indonesia
                </div>
                <div class="mt-4">
                  Approximatelly 54% percent of adults in Indonesia owned at
                  least one tablet, as of February 2021. The tablet ownership
                  rate in Indonesia has ...
                </div>
              </div>
            </va-card-content>
          </va-card>
        </div>
      </div>
      <div class="row">
        <div class="flex xs12 md4">
          <va-card>
            <va-card-title>
              <va-chart
                class="vertical-chart"
                :data="verticalBarChartData"
                type="vertical-bar"
              />
            </va-card-title>
            <va-card-content>
              <div>
                <div class="mb-2" style="color: #007CFF;">
                  Uncategorized
                </div>
                <div class="card-title">
                  Tablet Ownership di Indonesia
                </div>
                <div class="mt-4">
                  Approximatelly 54% percent of adults in Indonesia owned at
                  least one tablet, as of February 2021. The tablet ownership
                  rate in Indonesia has ...
                </div>
              </div>
            </va-card-content>
          </va-card>
        </div>
        <div class="flex xs12 md4">
          <va-card>
            <va-card-title>
              <va-chart
                class="vertical-chart"
                :data="verticalBarChartData"
                type="vertical-bar"
              />
            </va-card-title>
            <va-card-content>
              <div>
                <div class="mb-2" style="color: #007CFF;">
                  Uncategorized
                </div>
                <div class="card-title">
                  Tablet Ownership di Indonesia
                </div>
                <div class="mt-4">
                  Approximatelly 54% percent of adults in Indonesia owned at
                  least one tablet, as of February 2021. The tablet ownership
                  rate in Indonesia has ...
                </div>
              </div>
            </va-card-content>
          </va-card>
        </div>
        <div class="flex xs12 md4">
          <va-card>
            <va-card-title>
              <va-chart
                class="vertical-chart"
                :data="verticalBarChartData"
                type="vertical-bar"
              />
            </va-card-title>
            <va-card-content>
              <div>
                <div class="mb-2" style="color: #007CFF;">
                  Uncategorized
                </div>
                <div class="card-title">
                  Tablet Ownership di Indonesia
                </div>
                <div class="mt-4">
                  Approximatelly 54% percent of adults in Indonesia owned at
                  least one tablet, as of February 2021. The tablet ownership
                  rate in Indonesia has ...
                </div>
              </div>
            </va-card-content>
          </va-card>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.va-card {
  min-height: 400px;
}
.vertical-chart {
  height: 300px;
}
.card-title {
  font-size: 20px;
  color: #535353;
}
</style>

<script>
import { getVerticalBarChartData } from "@/data/charts/VerticalBarChartData";
import VaChart from "@/components/va-charts/VaChart.vue";
import { useGlobalConfig } from "vuestic-ui";

export default {
  components: { VaChart },
  data() {
    return {
      verticalBarChartData: null
    };
  },
  mounted() {
    this.verticalBarChartData = getVerticalBarChartData(this.theme);
  },
  computed: {
    theme() {
      return useGlobalConfig().getGlobalConfig().colors;
    }
  }
};
</script>
